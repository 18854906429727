import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ModalAlert } from "./Modal";
import axios from '../axios';

export const UsersDropDown = (props) => {

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  const [users = [], setUsers] = useState();
  const [buttonTitle, setButtonTitle] = useState("Person auswählen");

  const setName = (id, name) => {
    const requestOptions = {
      inquirer_id: props.ownID,
      pollee_id: id,
      userID: props.ownID
    };

    if (props.ownID && !props.ownRights) {
      axios.post(process.env.REACT_APP_API_URL + '/data/checkForDatasetPolleeByInquirer', requestOptions)
        .then((response) => {
          if (response.data.exists) {
            axios.post(process.env.REACT_APP_API_URL + '/data/getDatasetInquirer', requestOptions)
              .then((response) => {
                if (response.data.rows) {
                  props.setSurveyData(response.data.rows[0].data)
                }
              });
            props.setState({ [props.stateID]: id, [props.stateName]: name })
            setButtonTitle(name);
            setAlertMsg("Du hast eine schon eine Umfrage für diesen Mitarbeiter ausgefüllt!")
            setShowAlert(true)
          }
          else {
            props.setState({ [props.stateID]: id, [props.stateName]: name });
            setButtonTitle(name);
            props.setSurveyData();
          }

        });
    }
    else if (props.ownRights === 3) {
      setButtonTitle(name);
      props.setState({ ...props.state, [props.stateID]: id, [props.stateName]: name })
    }
  }

  useEffect(() => {
    const requestOptions = {
      rights: props.rights,
      userID: props.ownID
    };

    if (props.rights === 2) {
      axios.post(process.env.REACT_APP_API_URL + '/users/getUsersByRights', requestOptions)
        .then((response) => {
          setUsers(response.data)
        });
    }

    else if (props.rights === 1 && props.inquirer_ID) {
      setButtonTitle("Person auswählen");
      axios.post(process.env.REACT_APP_API_URL + '/users/getEmployeesBySupervisor', { inquirer_id: props.inquirer_ID, userID: props.ownID })
        .then((response) => {
          setUsers(response.data)
        });
    }

  }, [props.inquirer_ID, props.rights]);

  return (
    <>
      <ModalAlert show={showAlert} setState={setShowAlert} alert={alertMsg} />
      <DropdownButton id={"users_dropdown_btn" + props.rights} className="users_dropdown" title={buttonTitle} style={{ display: "inline-block", margin: 10 }}>
        {users.map((user, i) =>
          <Dropdown.Item key={i} onClick={() => setName(user.id, user.name)}>{user.name}</Dropdown.Item>)}
      </DropdownButton >
    </>
  );
}
