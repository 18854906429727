import React, { useState, useEffect } from "react";
import axios from '../axios';
import { ModalAlert } from "./Modal";
import json_survey from '../survey/survey.json'
import 'survey-core/defaultV2.css';
import './Survey.css';
import { Survey } from 'survey-react-ui';
import "survey-core/survey.i18n";
import { Model } from 'survey-core';
import { LayeredDarkPanelless } from "survey-core/themes/default-light-panelless";

export const SurveyModel = (props) => {

  const [name, setName] = useState("Kein-Vorgesetzter-gefunden!");
  const [show, setShow] = useState(false);
  const [showDeadline, setShowDeadline] = useState(true);
  const [prevData, setPrevData] = useState();

  const survey = new Model(json_survey);
  survey.setVariable('rights', props.rights);
  survey.setVariable('name', name);
  survey.widthMode = "responsive";
  survey.applyTheme(LayeredDarkPanelless);
  survey.locale = 'de';

  useEffect(() => {

    if (props.rights === 1) {
      const requestOptions = {
        pollee_id: props.id
      };
      if (!props.saveSurveyData)
        setPrevData(window.localStorage.getItem("surveyEmp") || null);

      axios.post(process.env.REACT_APP_API_URL + '/users/getEmployeesSupervisor', requestOptions)
        .then((response) => {
          if (response.data.length !== 0)
            setName(response.data[0].name.split(" ")[0])
        });
    }

    if (props.rights === 2) {
      setName(props.p_Name.split(" ")[0])
    }
  }, [props.rights, props.id, props.p_Name]);

  function saveSurveyData(survey) {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    if (props.rights === 1)
      window.localStorage.setItem("surveyEmp", JSON.stringify(data));
  }



  if (props.surveyData) {
    survey.data = props.surveyData;
    survey.mode = "display";
  }

  if (props.deadline) {
    survey.mode = "display";
  }

  if (prevData) {
    const data = JSON.parse(prevData);
    survey.data = data;
    if (data.pageNo) {
      survey.currentPageNo = data.pageNo;
    }
  }


  if (props.rights === 2) {
    survey.onValueChanged.add(function (survey, options) {
      if (!props.pollee_ID) {
        setShow(true)
      }
    });
  }

  survey.onValueChanged.add(saveSurveyData);
  survey.onCurrentPageChanged.add(saveSurveyData);

  survey.onComplete.add(function (sender, options) {
    var result = survey.data;
    survey.getAllQuestions(true).forEach(function (question) {
      if (result[question.name] === undefined) {
        result[question.name] = "Keine Antwort";
      }
    })
    survey.completedHtml = "<h1>Vielen Dank, die Umfrage wurde abgeschlossen!</h1>";

    if (props.rights === 1) {
      axios.post(process.env.REACT_APP_API_URL + '/data/createDatasetPollee', { data: result, userID: props.id })
        .then(response => {
          if (response.status === 200) {
            options.showSaveInProgress("Daten werden gespeichert...");
            window.localStorage.setItem("surveyEmp", "");
          }
        })
    }

    else if (props.rights === 2) {
      if (props.pollee_ID)
        axios.post(process.env.REACT_APP_API_URL + '/data/createDatasetInquirer', { data: result, pollee_id: props.pollee_ID, inquirer_id: props.ownID })
          .then(response => {
            if (response.status === 200) {
              options.showSaveInProgress("Daten werden gespeichert...");
            }
          })
    }
  });

  return (
    <>
      {props.rights === 1 &&
        <>
          <div style={{ width: '100%' }}>
            <Survey model={survey} id="surveyContainer" />
          </div>
        </>
      }
      {props.rights === 2 &&
        <>
          <ModalAlert show={show} setState={setShow} alert={"Es wurde kein Mitarbeiter ausgewählt!"} />
          <div style={{ width: '100%' }}>
            <Survey model={survey} id="surveyContainer" />
          </div>
        </>
      }
      {props.deadline &&
        <ModalAlert show={showDeadline} setState={setShowDeadline} alert={"Die Umfrage ist abgelaufen und kann nicht mehr ausgefüllt werden, da die Deadline erreicht wurde."} />
      }
    </>
  );

};

