// Basic React Hooks
import React, { useState, useEffect } from "react";
// Authenticaten with MS-Account
import { AuthenticatedTemplate } from "@azure/msal-react";
// The Survey users fill out
import { SurveyModel } from "./components/Survey";
// Dropdown where users can be selected  
import { UsersDropDown } from "./components/DropDown";
// The Admin UI
import { UserManagement } from "./components/UserManagement";
// Where filled out surveys will be displayed
import { Chart } from "./components/Graph";
// A single Button to restet all survey data
import { ResetDataButton } from "./components/ResetDataButton";
import './App.css';
import axios from './axios';


/**
 * The main part of the react page  
 * @return {HTML} React generated HTML 
*/
function App(props) {

  /**
   * Determines if the button used for the data transfer to the DB should be visible, using react state 
   * @param {boolean} sendButtonDisabled The state of the button
   * @param {object} setSendButtonDisabled Set method for the state
  */
  const [deadline, setDeadline] = useState();
  const [surveyData, setSurveyData] = useState();

  useEffect(() => {

    if (props.rights === 1 || props.rights === 2) {
      axios.get(process.env.REACT_APP_API_URL + '/users/checkDeadline')
        .then((response) => {
          if (response.data.overDeadline) {
            setDeadline(response.data.overDeadline)
          }
        });
    }

    if (props.rights === 1) {
      const requestOptions = {
        pollee_id: props.userID[0].id
      };

      axios.post(process.env.REACT_APP_API_URL + '/data/checkForDatasetPollee', requestOptions)
        .then((response) => {
          if (response.data.exists) {
            axios.post(process.env.REACT_APP_API_URL + '/data/getDatasetPollee', requestOptions)
              .then((response) => {
                if (response.data.rows) {
                  setSurveyData(response.data.rows[0].data);
                }
              })
          }
          else
            setSurveyData();
        });
    }
    else
      setSurveyData();
  }, [props.rights, props.userID]);


  if (props.rights === 1) {
    return (
      <>
        <AuthenticatedTemplate>
          <div>
            <h2 className="surveyTitle sd-header__text sd-title">avarno Mitarbeiterumfrage</h2>
            <SurveyModel rights={1} id={props.userID[0].id} surveyData={surveyData} deadline={deadline} />
          </div>
        </AuthenticatedTemplate>
      </>
    );
  }

  if (props.rights === 2) {
    return (
      <>
        <AuthenticatedTemplate>
          <div id="surveyHeader">
            <h2 className="surveyTitle sd-header__text sd-title">avarno Mitarbeiterumfrage</h2>
            <UsersDropDown setState={props.setGraphData} stateID={"pollee_ID"} stateName={"pollee_name"} ownID={props.userID[0].id} rights={1} inquirer_ID={props.userID[0].id} setSurveyData={setSurveyData} />
          </div>
          <SurveyModel rights={2} ownID={props.userID[0].id} p_Name={props.graphData.pollee_name} pollee_ID={props.graphData.pollee_ID} deadline={deadline} surveyData={surveyData} />
        </AuthenticatedTemplate>
      </>
    );
  }

  if (props.rights === 3) {
    return (
      <>
        <AuthenticatedTemplate>
          <div>
            <p style={{ marginLeft: 20, display: "inline-block" }}>Mitarbeiter:</p>
            <UsersDropDown ownID={props.userID[0].id} ownRights={3} setState={props.setGraphData} state={props.graphData} stateID={"inquirer_ID"} stateName={"inquirer_name"} rights={2} />
            <p style={{ marginLeft: 30, display: "inline-block" }}>Vorgesetzter:</p>
            <UsersDropDown ownID={props.userID[0].id} ownRights={3} setState={props.setGraphData} state={props.graphData} stateID={"pollee_ID"} stateName={"pollee_name"} rights={1} inquirer_ID={props.graphData.inquirer_ID} style={{ display: "inline-block" }} />
          </div>
          <Chart pollee_ID={props.graphData.pollee_ID} pollee_Name={props.graphData.pollee_name} inquirer_ID={props.graphData.inquirer_ID} inquirer_Name={props.graphData.inquirer_name} />
        </AuthenticatedTemplate>
      </>
    );
  }

  if (props.rights === 4) {
    return (
      <>
        <AuthenticatedTemplate>
          <h2 style={{ marginLeft: 5 }}>Benutzerverwaltung</h2>
          <UserManagement ownID={props.userID[0].id} />
          <ResetDataButton ownID={props.userID[0].id} />
        </AuthenticatedTemplate >
      </>
    );
  }

}

export default App;